import { ConfigProvider as ConfigProvider5, Layout } from "antd-v5";
import { ConfigProvider as ConfigProvider4 } from "antd";
/**
 * @todo Needs to be dynamically loaded in the future.
 */
import enUS from "antd/lib/locale/en_US";
import ptBR from "antd/lib/locale/pt_BR";
import enUS5 from "antd-v5/lib/locale/en_US";
import ptBR5 from "antd-v5/lib/locale/pt_BR";
import { Locale } from "antd/lib/locale-provider";
import { Locale as Locale5 } from "antd-v5/lib/locale";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";

import { themeConfig } from "~/src/styles/antd-theme-config";

/**
 * Container to wrap the AntD theme configuration, applying CSS variables across the application.
 */
const ThemeContainer = Layout.Content;

const antdLoadedLocales: {
  [key: string]: Locale;
} = {
  "en-US": enUS,
  "pt-BR": ptBR,
};

const antdLoadedLocales5: {
  [key: string]: Locale5;
} = {
  "en-US": enUS5,
  "pt-BR": ptBR5,
};

type Props = {
  children: ReactNode;
};

export const AntdConfig = ({ children }: Props) => {
  const { t } = useTranslation("validation");
  const { locale, defaultLocale } = useRouter();

  const antdLocale = antdLoadedLocales[locale ?? defaultLocale];
  const antdLocale5 = antdLoadedLocales5[locale ?? defaultLocale];

  if (typeof antdLocale === "object") {
    antdLocale.Form.defaultValidateMessages = t("validation", {
      returnObjects: true,
    });
  } else {
    // If anything prevented Ant'd locale from loading we need to know.

    console.error(`Antd's locale for '${locale}' haven't been loaded`);
  }

  return (
    <ConfigProvider5 locale={antdLocale5} theme={themeConfig} prefixCls="ant-5">
      <ThemeContainer>
        <ConfigProvider4 locale={antdLocale} space={{ size: "large" }}>
          {children}
        </ConfigProvider4>
      </ThemeContainer>
    </ConfigProvider5>
  );
};
